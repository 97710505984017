<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="container shape-container d-flex align-items-center text-center-sm"
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  Privacy Policy
                </h1>
                <p class="f-16 text-dark mb-0">
                  <!-- Welcome to Imperium website (the "Site"). -->
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg w-100"
                    src="/assets/images/svgs/privacy.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div id="terms-and-conditions">
              <section class="introduction">
                <p>
                  At Imperium, we take the privacy of your personal information
                  seriously and remain committed to protecting and ensuring that
                  your Personal Data is treated in a fair and lawful manner in
                  all our dealings with you.
                </p>
                <p>
                  This Privacy Policy (“Policy”) has been put together to
                  explain to you what we do with the personal information that
                  you provide to us when you visit our website, while using any
                  of our digital or mobile platforms/services, our Mobile
                  Banking App, mobile authentication service, when communicating
                  with us through any of our social media or other interactive
                  channels or any other engagement with us (collectively
                  “Services”).
                </p>
                <p>
                  Throughout this document, “Imperium”, “Site”, “we”, “us”,
                  “our” and/or “ours” refer to Imperium incorporated under the
                  laws of the Federal Republic of Nigeria with its registered
                  office at 20 Marina, Lagos. The reference
                  to ‘you’ or ‘your’, means you, any authorised person on your
                  account, anyone who conducts your banking services for you or
                  other related people (including authorised signatories,
                  partners or any authorised third party).
                </p>
                <p>
                  By continuing to visit our website

                  <!-- s (
                  <a target="_blank" href="https://www.sterling.ng"
                    >www.sterling.ng</a
                  >
                  ), or using our mobile and digital platforms, social media
                  platforms and other Sterling customer touch-points and
                  utilising Sterling services, including -->

                  <a href="https://www.imperiumng.com" target="_blank"
                    >www.imperiumng.com</a
                  >, you accept and consent to the provisions described in this
                  Policy.
                </p>
                <header>
                  <h3>1. The information we collect about you</h3>
                </header>
                <article class="details">
                  <p>
                    We gather information from you for a number of reasons. We
                    may need you to provide your Personal Information/Data such
                    as your name, contact details, identification, work and
                    residential addresses, gender, positions held, forms
                    submitted, Bank Verification Number (BVN), payment details
                    and other enquiry details which would enhance your service
                    experience. The Personal Data we collect, fall into various
                    categories, such as:
                  </p>
                  <!-- <p>
                    User data collected helps us to personalize our website
                    according to each user’s wishes and preferences. Offering
                    you the most spontaneous and friendly surfing experience is
                    our priority.
                  </p> -->
                  <ol class="alphas">
                    <li>
                      <span class="hlgt">
                        Details about your banking activities and transactions
                        with us.
                      </span>
                      <br />
                      <p>
                        This includes information on any bank accounts you use,
                        debit card numbers, financial history, information you
                        provide to deliver payment initiation services and
                        account information services regarding accounts you hold
                        with other providers.
                      </p>
                    </li>
                    <li>
                      <span class="hlgt">
                        Information on how you use your device/ Usage Data.
                      </span>
                      <br />
                      <p>
                        We may also collect information that your browser sends
                        whenever you visit our website or banking applications
                        or when you access our Services by or through a mobile
                        device (“Usage Data”). This Usage Data may include
                        information such as your computer’s Internet Protocol
                        address (e.g. IP address), browser type, browser
                        version, the pages of our Service that you visit, the
                        time and date of your visit and the time spent on those
                        pages amongst other diagnostic data. When you access the
                        Service by or through a mobile device or use the mobile
                        app, this Usage Data may include information such as the
                        type of mobile device you use, your mobile device unique
                        ID, the IP address of your mobile device, your mobile
                        operating system, the type of mobile Internet browser
                        you use, unique device identifiers and other diagnostic
                        data.
                      </p>
                    </li>
                    <li>
                      <span class="hlgt"> Sensitive data </span>
                      <br />
                      <p>
                        We may hold information about you that reveals your
                        racial or ethnic origin, political opinions, religious
                        or philosophical beliefs, genetic or biometric data or
                        data concerning your health or sexual orientation, or
                        data relating to criminal convictions or offences or
                        information which is used to uniquely identify you, (for
                        example your fingerprint, facial recognition or voice
                        recording). We will only hold this data when we need to
                        for the purposes of the product or services we provide
                        to you, where we are required to process the data for a
                        public interest purposes, or where we have a legal
                        obligation or your consent to do so.
                      </p>
                    </li>
                    <li>
                      <span class="hlgt">
                        Information which you have consented to us using
                      </span>
                      <br />
                      <p>
                        We may collect information about your marketing
                        preferences to provide you with information about
                        relevant services, products and offers that we think may
                        be of interest to you.
                      </p>
                    </li>
                    <li>
                      <span class="hlgt"> Tracking and Cookies Data </span>
                      <br />
                      <p>
                        We use cookies and similar tracking technologies to
                        track the activity on our Services and hold certain
                        information. Cookies are files with small amount of data
                        which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a website and
                        stored on your device. Tracking technologies also used
                        are beacons, tags, and scripts to collect and track
                        information and to improve and analyse our Service. You
                        can instruct your browser to refuse all cookies or to
                        indicate when a cookie is being sent. You can also
                        refuse permissions to read your phone data by the mobile
                        application. However, if you do not accept cookies on
                        your browser or allow permissions on your mobile device,
                        our online service experience to you may be degraded and
                        you may not be able to use some portions of our Service.
                        We may also collect information about your internet
                        browser settings and Internet Protocol (IP) address and
                        other relevant information to help us identify your
                        geographic location when providing you with our
                        services.
                      </p>
                      <!-- <br /> -->
                      <span class="hlgt"> Examples of Cookies we use: </span>
                      <ol class="romans">
                        <li>
                          <span class="hlgt">Session Cookies.</span> We use
                          Session Cookies to operate our Service. Session
                          cookies will expire at the end of your browser session
                          and allow us to link your actions during that browser
                          session.
                        </li>
                        <li>
                          <span class="hlgt">Preference Cookies.</span> We use
                          Preference Cookies to remember your preferences and
                          actions, across multiple sites.
                        </li>
                        <li>
                          <span class="hlgt">Security Cookies.</span> We use
                          Security Cookies for security purposes.
                        </li>
                        <li>
                          <span class="hlgt">
                            Information from social networks or online
                            accounts.</span
                          >
                          This includes Information from any social media
                          profiles or any accounts that you share with us.
                        </li>
                        <li>
                          <span class="hlgt">Other personal information</span>
                        </li>
                      </ol>
                      <br />
                    </li>
                    <!-- <li>
                      Other personal data which we collect includes image
                      recordings this could include CCTV images of you at our
                      bank branches, offices and ATMs but only for security
                      purposes, to help forestall crime.
                    </li> -->
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>2. How we use your personal Information</h3>
                </header>
                <article class="details">
                  <p>
                    To the extent permissible under applicable law, we may use
                    your information for the following legitimate actions:
                  </p>
                  <ol class="alphas">
                    <li>
                      Determine your eligibility for our products and services.
                    </li>
                    <li>
                      Verify your identity when you access your account
                      information
                    </li>
                    <li>
                      Administer your accounts or other products and services
                      that we or our partners/affiliates may provide to you
                    </li>
                    <li>Respond to your requests and communicate with you.</li>
                    <li>For understanding your financial needs.</li>
                    <li>
                      Prevention of crime, fraud, money laundering or terrorism
                      financing activities
                    </li>
                    <li>Managing our risks</li>
                    <li>Reviewing credit or loan eligibility.</li>
                    <li>
                      For marketing the products and services of Imperium,
                      related entities and affiliates. We may send you marketing
                      and promotional messages by post, email, telephone, text,
                      secure messaging, Mobile app, or through our social media
                      channels. You can change your mind on how you wish to
                      receive marketing messages from us, or opt out of
                      receiving such messages at anytime. However, we will
                      continue to use your contact details to send you important
                      information regarding your dealings with us.
                    </li>
                    <li>
                      Process transactions, design products and profile
                      customers
                    </li>
                    <li>Notify you about changes to our Services</li>
                    <li>
                      Allow you to participate in interactive features of our
                      Services when you choose to do so.
                    </li>
                    <li>
                      Provide customer care and support and for internal
                      operations, including troubleshooting, data analysis,
                      testing, security, fraud-detection, and account
                      management.
                    </li>
                    <li>
                      Process your information for audit, statistical or
                      research purposes in order to help us understand trends in
                      our customer behaviour and to understand our risks better
                      and curate products and services that are suitable to our
                      customers’ needs
                    </li>
                    <li>
                      Monitor our conversation with you when we speak on the
                      telephone (for example, to check your instructions to us,
                      to analyse, to assess and improve customer service; for
                      training and quality assurance purposes; for verification,
                      fraud analysis and prevention purposes
                    </li>
                    <li>Recover any debts that you may owe the Bank.</li>
                    <li>
                      Carry out analysis to evaluate and improve our business
                    </li>
                    <li>Monitor the usage of our Services</li>
                    <li>Detect, prevent and address technical issues</li>
                    <li>
                      Prevent fraud and enhance security of your account or our
                      service platform.
                    </li>
                    <li>
                      Comply with and enforcing applicable legal and regulatory
                      requirements, relevant industry standards, contractual
                      obligations and our policies
                    </li>
                    <li>
                      Provide you with tailored content and marketing messages
                      such as recommending other products or services we believe
                      you may be interested in
                    </li>
                    <li>For other purposes required by law or regulation</li>
                  </ol>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>3. How do we share your information?</h3>
                </header>
                <article class="details">
                  <p>
                    We may share the information about you and your dealings
                    with us, to the extent permitted by law, with the following:
                  </p>
                  <ol class="alphas">
                    <li>Sterling Branches and subsidiaries</li>
                    <li>
                      Legal/Regulatory Authorities – It may be necessary by law,
                      legal process, litigation, and/or requests from public and
                      governmental authorities for FITC to disclose your
                      personal information. We may also disclose information
                      about you if we determine that for purposes of national
                      security, law enforcement, or other issues of public
                      importance, disclosure is necessary or appropriate;
                    </li>
                    <li>Professional Advisers: Auditors/Legal Advisers</li>
                    <li>Credit Agencies;</li>
                    <li>Correspondent Banks;</li>
                    <li>External Auditors;</li>
                    <li>
                      Strategic partners/service providers – for the purpose of
                      providing our services to you. Your Personal information
                      will not be shared with third parties for their marketing
                      purposes.
                    </li>
                    <li>
                      We may also disclose your Personal Information in good
                      faith and belief that such action is necessary in any of
                      the following circumstances:
                      <ol class="romans">
                        <li>We have your consent to share the information;</li>
                        <li>To comply with a legal obligation;</li>
                        <li>
                          To bring you improved service across our array of
                          products and services, when permissible under relevant
                          laws and regulations, by disclosing your personal
                          information with Sterling Bank’s affiliated websites
                          and businesses;
                        </li>
                        <li>
                          To protect and defend the rights or property of the
                          Bank;
                        </li>
                        <li>
                          To prevent or investigate possible wrongdoing in
                          connection with our Service;
                        </li>
                        <li>
                          To protect the personal safety of users of our Service
                          or the public;
                        </li>
                        <li>To protect against legal liability;</li>
                        <li>
                          in the event of a reorganisation, merger, or sale we
                          may transfer any and all personal information we
                          collect to the relevant third party or
                        </li>
                        <li>
                          We find that your actions on our web sites or banking
                          applications violate any part of our Privacy Policy;
                        </li>
                      </ol>
                    </li>
                  </ol>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>4. Joint Account Holders</h3>
                </header>
                <article class="details">
                  <p>
                    When you open or use a joint account, your Personal Data
                    will be shared with the other Account Holder. For instance,
                    the joint account holder will be able to see transactions
                    made by you. We may act on the authority of one joint
                    Account Holder to share or allow a third party access to
                    your account information for the provision of payment
                    services, including transaction details. In this regard, we
                    will treat the authority of one Account Holder as
                    authorisation on behalf of the other Account Holder.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>5. Guarantors</h3>
                </header>
                <article class="details">
                  <p>
                    We may share your information with any person or entity
                    which guarantees your credit obligations to us as part of
                    security requirement for a credit scheme or facility
                    advanced to you.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>6. Automated Processing and Analytics</h3>
                </header>
                <article class="details">
                  <p>
                    We may use automated statistical analysis of the information
                    you provide to us about you to make automated lending
                    decisions, when you apply for a financial product, for
                    example, a loan facility or use such statical data to manage
                    existing credit arrangements you my have with us. We may
                    also employ statistical analysis to determine whether a
                    product or service best suits your needs or otherwise.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>7. How we secure your Information</h3>
                </header>
                <article class="details">
                  <p>
                    We have implemented appropriate organisational and technical
                    measures to keep your Personal Information/Data confidential
                    and secure. This includes the use of encryption, access
                    controls and other forms of security to ensure that your
                    data is protected. We require all parties including our
                    staff and third-parties processing data on our behalf to
                    comply with relevant policies and guidelines. Where you have
                    a password which grants you access to specific areas on our
                    site or to any of our services, you are responsible for
                    keeping this password confidential. We request that you do
                    not share your password or other authentication details
                    (e.g. token generated codes) with anyone.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>8</h3>
                </header>
                <article class="details">
                  <p>
                    Although we have taken measures to secure and keep your
                    information confidential, because the security of your data
                    is important to us, please be aware that no method of
                    transmission over the Internet, or method of electronic
                    storage can guarantee 100% security at all times. While we
                    strive to use commercially acceptable means to protect your
                    Personal Data, we cannot guarantee its absolute security,
                    you are responsible for securing and maintaining the privacy
                    of your password and Account/profile registration
                    information and verifying that the Personal Data we maintain
                    about you is valid, accurate and up to date. If we receive
                    instructions using your account login information, we will
                    consider that you have authorised the instructions and
                    process your instruction accordingly and without incurring
                    any liability for doing so.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>9. How long we keep your information.</h3>
                </header>
                <article class="details">
                  <p>
                    We retain your Information for as long as the purpose for
                    which the information was collected continues. The
                    information is then securely destroyed unless its retention
                    is required to satisfy legal, regulatory, internal
                    compliance or accounting requirements or to protect
                    Sterling’s interest.
                    <br />
                    <br />
                    Please note that regulations may require Sterling to retain
                    your personal data for a specified period even after the end
                    of your banking relationship with us.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>10. Information from locations outside Nigeria</h3>
                </header>
                <article class="details">
                  <p>
                    If you are located outside Nigeria and choose to provide
                    information to us, please note that the data, including
                    Personal Data, will be processed in Nigeria. Your consent to
                    this Privacy Policy followed by your submission of such
                    information represents your agreement to that transfer.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>11. Accuracy and update of your Information</h3>
                </header>
                <article class="details">
                  <p>
                    You are responsible for making sure the information provided
                    to the Bank is accurate and should inform the Bank on any
                    changes as it occurs, this will enable us to update your
                    information with us.
                    <br />
                    <br />
                    Any changes will affect only future uses of your Personal
                    Information. Subject to applicable law, which might, from
                    time to time, oblige us to store your Personal Information
                    for a certain period of time, we will respect your wishes to
                    correct inaccurate information. Otherwise, we will hold your
                    Personal Information for as long as we believe it will help
                    us achieve our objectives as detailed in this Privacy
                    Policy.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>12. Your Rights</h3>
                </header>
                <article class="details">
                  <p>
                    You have certain rights in relation to the personal data we
                    collect as provided by the Nigeria Data Protection
                    Regulation 2019 (NDPR), these rights include:
                  </p>
                  <ol class="alphas">
                    <li>A right to access your personal data</li>
                    <li>
                      A right to rectify/update your information in our
                      possession
                    </li>
                    <li>A right to request the erasure of personal data</li>
                    <li>
                      A right to withdraw your consent to processing of personal
                      data. This will however not affect the legality of
                      processing carried out prior to any such withdrawal
                    </li>
                    <li>
                      Right to object to processing of personal data. This will
                      only be applicable where there are no legal or operational
                      reasons.
                    </li>
                    <li>
                      Request that your personal data be made available to you
                      in a common electronic format and/or request that such
                      data be sent to a third party.
                    </li>
                    <li>
                      Request that your information be erased. We might continue
                      to retain such data if there are valid legal, regulatory
                      or operational reasons
                    </li>
                  </ol>
                  <p>
                    These rights are however subject certain limitations as
                    provided in the NDPR.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>13. Privacy of minors</h3>
                </header>
                <article class="details">
                  <p>
                    We do not knowingly collect names, email addresses, or any
                    other personally identifiable information from children
                    through the internet or any other touch-points. We do not
                    allow children under the age of 18 to open accounts nor
                    provide online banking services for children less than 18
                    years of age without the consent of a guardian. If you are a
                    parent or guardian and you are aware that your child has
                    provided us with Personal Data without verification of
                    parental consent, please promptly contact us.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>14. Social Media Platforms</h3>
                </header>
                <article class="details">
                  <ol class="alphas">
                    <li>
                      We operate and communicate through our designated pages
                      and accounts on some social media platforms to communicate
                      and engage with our customers. We monitor and record
                      comments and posts made about us on these channels so that
                      we can improve our Services. The general public can access
                      and read any information posted on these sites. Please
                      note that any content you post to such social media
                      platforms is subject to the applicable social media
                      platform’s terms of use and privacy policies. We recommend
                      that you review the information carefully in order to
                      better understand your rights and obligations regarding
                      such content.
                    </li>
                    <li>
                      Our Services may allow you to connect and share your
                      actions, comments, content, and information publicly or
                      with friends. We are not responsible for maintaining the
                      confidentiality of any information you share publicly or
                      with friends.
                    </li>
                    <li>
                      Our Services may also allow you to connect with us on,
                      share on, and use third-party websites, applications, and
                      services. Please be mindful of your personal privacy needs
                      and the privacy needs of others, as you choose whom to
                      connect with and what to share and make public. We cannot
                      control the privacy or security of information you choose
                      to make public or share with others. We also do not
                      control the privacy practices of third parties. Please
                      contact those sites and services directly if you want to
                      learn about their privacy practices.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>15. Career Platforms</h3>
                </header>
                <article class="details">
                  <p>
                    As part of our recruitment process and as an applicant, you
                    explicitly consent to the collection, use, transfer, and
                    storage or in any other form of your personal data contained
                    in application forms/letters, curriculum vitae (CV)/resumes
                    obtained from your identity document(s) or collected through
                    interviews/other forms assessment by Sterling Bank Plc or
                    its affiliates. This information is for the exclusive
                    purpose of assessing and evaluating applicants suitability
                    for employment in any current or prospective position within
                    our organisation, verifying applicants identity and the
                    accuracy of your details provided to us or for other related
                    purposes. We shall, in line with our internal policies,
                    controls and relevant Data Protection Regulations ensure
                    that this data is not disclosed or assessed by unauthorised
                    persons. By providing any information on Sterling’s career
                    page, you confirm that you have read the terms and privacy
                    statement and accept it. As an applicant, you consent to
                    Sterling Bank using the data provided in accordance with
                    terms described above.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>16. Third Party Websites</h3>
                </header>
                <article class="details">
                  <p>
                    Our website, related websites and mobile applications may
                    have links to or from other websites that are not operated
                    by us. We have no control over and assume no responsibility
                    for the security, privacy practices or content of third
                    party websites or services. We recommend that you always
                    read the privacy and security statements on these websites.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>17. Service Providers</h3>
                </header>
                <article class="details">
                  <p>
                    We may employ third party companies and individuals to
                    facilitate our Service (“Service Providers”), to provide the
                    Service on our behalf, to perform specific Service-related
                    roles or to assist us in analysing how our Service is used.
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose outside of the
                    service-specific need for which the data is required.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>18. Changes to this Policy</h3>
                </header>
                <article class="details">
                  <p>
                    This Privacy Policy is effective as of the date stated above
                    and will remain in effect except with respect to any changes
                    in its provisions in the future, which will be in effect
                    immediately after being posted on our website.
                    <br /><br />
                    Based on the changing nature of privacy laws, user needs and
                    our business, we may modify this Privacy Policy from time to
                    time. Any change to our privacy policy will be communicated
                    on our website, via email or by placing a notice on our
                    Platform and this will be effective as soon as published.
                    Accordingly, we encourage periodic reviews of this Privacy
                    Policy for awareness of any changes that may have occurred.
                    Your continued use of the Services after we post any
                    modifications to the Privacy Policy on our website will
                    constitute your acknowledgment of the modifications and your
                    consent to abide and be bound by the modified Privacy
                    Policy.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header>
                  <h3>19. Contact Us</h3>
                </header>
                <article class="details">
                  <p>
                    If you have any questions, comments or requests in relation
                    to this Privacy Policy or objections, complaints or
                    requirements in relation to the use of your personal data,
                    please contact us by sending an email to info@imperiumng.com
                    or, write a letter addressed as follows:
                    <br /><br />
                    The Data Protection Officer
                    <br /><br />

                    <!-- Sterling Bank Plc
                    <br /><br /> -->

                    20 Marina, Lagos.
                  </p>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.headerimg {
  width: 100%;
}
#terms-and-conditions {
  color: black;
  h3 {
    color: #219653;
    opacity: 0.8;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
#terms-and-conditions .details table {
  width: 80%;
  font-size: 1.1rem;
  border-collapse: collapse;
  margin: 0 auto 2rem;
  th {
    font-weight: 600;
  }
}
#terms-and-conditions .details table td,
#terms-and-conditions .details table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  padding-left: 2rem;
}
article.details p {
  line-height: 30px;
  margin-bottom: 40px;
}
.return-policies .bullet-points {
  padding-left: 30px;
}
.return-policies strong,
.mysp {
  margin-right: 10px;
  display: inline-block;
}
.mysp,
.point {
  margin-bottom: 16px;
}
.point {
  font-weight: 600;
  font-size: 1.2rem;
}
ol.alphas {
  list-style-type: lower-alpha;
}
ol.romans {
  list-style-type: lower-roman;
}
</style>